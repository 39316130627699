<template>
  <div v-if="!close" class="modal">
    <div class="modal__container">
      <div class="modal__new">
        <div class="modal__new__close">
          <button @click.prevent="openIsAuthActive()">
            <img src="@/assets/img/icons/close.svg" alt="" />
          </button>
        </div>
        <div class="modal__new__title">
          Поздравляем, вы участник Party Club!
        </div>
        <div class="modal__new__descr">Теперь вам доступно:</div>
        <div v-for="(bonus, index) in bonuses" :key="index" class="modal__pros">
          <img :src="bonus.img" alt="" />
          <p>{{ bonus.txt }}</p>
        </div>
        <button class="modal__new__button" @click.prevent="openIsAuthActive()">Здорово</button>
      </div>
    </div>
  </div>
</template>

<script>
import {  mapActions } from "vuex";
import { actionTypes } from "@/store";  
export default {
  name: "AppModalParty",
  methods: {

    ...mapActions({
      openIsAuthActive: actionTypes.changeIsAuthActive,
    }),
  },
  data() {
    return {
      close: false,
      bonuses: [
        {
          img: require("@/assets/img/icons/percent.svg"),
          txt: "участие в акциях и розыгрышах крутых призов",
        },
        {
          img: require("@/assets/img/icons/bonus.svg"),
          txt: "оплата покупок накапливаемыми бонусами",
        },
        {
          img: require("@/assets/img/icons/sale.svg"),
          txt: "Горячие скидки на алкоголь и табачную продукцию",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
