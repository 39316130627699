<template>
  <div v-if="!close" class="modal">
    <div class="modal__new">
      <div class="modal__new__close__sms">
        <button class="back">
          <svg
            width="7"
            height="16"
            viewBox="0 0 7 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 14.5697L1.0617 16L7 8L1.0617 0L0 1.4303L4.87661 8L0 14.5697Z"
              fill="currentColor"
            />
          </svg>
          <p>Назад</p>
        </button>
        <button class="close" @click="close = true">
          <img src="@/assets/img/icons/close.svg" alt="" />
        </button>
      </div>
      <div class="modal__new__title">Теперь введите код из смс</div>
      <div class="modal__new__descr">
        Мы отправили сообщение на номер <br />
        +7 747 555 55 55
      </div>
      <form class="club-sms">
        <input
          v-model="sms.code_1"
          ref="firstNumber"
          maxlength="1"
          type="text"
          placeholder="_"
          v-mask="'#'"
          class="club-sms-input"
          @keyup="addInput"
          :class="{ focus: sms.code_1.length > 0 }"
        />
        <input
          ref="secondNumber"
          v-model="sms.code_2"
          maxlength="1"
          placeholder="_"
          type="text"
          class="club-sms-input"
          @keyup="addInput"
          :class="{ focus: sms.code_2.length > 0 }"
        />
        <input
          v-model="sms.code_3"
          ref="thirdNumber"
          maxlength="1"
          type="text"
          placeholder="_"
          class="club-sms-input"
          @keyup="addInput"
          :class="{ focus: sms.code_3.length > 0 }"
        />
        <input
          v-model="sms.code_4"
          ref="fourthNumber"
          maxlength="1"
          placeholder="_"
          type="text"
          class="club-sms-input"
          @keyup="addInput"
          :class="{ focus: sms.code_4.length > 0 }"
        />
      </form>
      <button class="modal__new__button" @click="sendCode">Продолжить</button>
      <div class="modal__send">
        <p v-show="elementVisible" class="hide__element">
          Отправить код еще раз через {{ timerCount }} секунд
        </p>
        <button v-show="!elementVisible">
          <span>Отправить код еще раз</span>
        </button>
        <p>Проблемы со входом? <span>позвоните на номер 7766</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      container: document.getElementsByClassName("club-sms")[0],
      timerCount: 20,
      elementVisible: true,
      close: false,
      time: 0,
      sms: {
        code_1: "",
        code_2: "",
        code_3: "",
        code_4: "",
      },
    };
  },
  created() {
    if (this.timerCount > 0) {
      this.time = setTimeout(() => (this.elementVisible = false), 20000);
      return this.time;
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    sendCode(){
      this.$emit('codeSubmitted', false)
    },
    addInput(e) {
     console.log(e); 
     if (!isNaN(Number(e.key))) {
       if (e.target.nextElementSibling) {
        setTimeout(() => {
          e.target.nextElementSibling.focus()
        }, 100);
       } else {
         alert('у нас пока нет апишки на это');
       }
     } else if(e.key === 'Backspace'){
       if (e.target.previousElementSibling) {
         e.target.previousElementSibling.focus()
       } else {
         this.sms.code_1=''
         this.sms.code_2=''
         this.sms.code_3=''
         this.sms.code_4=''
       }
     }else{
       setTimeout(() => {
        e.target.value = ''
       }, 100);
     }
      // if (e.code !== "Backspace") {
      //   if (this.currentRefs < 3) {
      //     this.currentRefs++;
      //     this.smsInputRefs[this.currentRefs]?.focus();
      //   } else if (this.sumCode(this.sms).length === 4) {
      //     // this.sendCode();
      //   }
      // } else {
      //   if (this.currentRefs > 0) {
      //     this.currentRefs--;
      //     this.smsInputRefs[this.currentRefs]?.focus();
      //   }
      // }
    },
    sumCode(items) {
      let sum = "";
      for (let item of Object.values(items)) {
        sum += item;
      }
      return sum;
    },
    deleteInput(e) {
      if (e.code === "Backspace") {
        this.currentRefs--;
        this.smsInputRefs[this.currentRefs]?.focus();
      }
    },
  },
  smsInputRefs() {
    return [
      this.$refs.firstNumber,
      this.$refs.secondNumber,
      this.$refs.thirdNumber,
      this.$refs.fourthNumber,
    ];
  },
  setAgain() {
    this.elementVisible = true;
    this.time = 20;
  },
  container(e) {
    var target = e.srcElement || e.target;
    var maxLength = parseInt(target.attributes["maxlength"].value, 10);
    var myLength = target.value.length;
    if (myLength >= maxLength) {
      var next = target;
      while ((next = next.nextElementSibling)) {
        if (next == null) break;
        if (next.tagName.toLowerCase() === "input") {
          next.focus();
          break;
        }
      }
    }
    // Move to previous field if empty (user pressed backspace)
    else if (myLength === 0) {
      var previous = target;
      while ((previous = previous.previousElementSibling)) {
        if (previous == null) break;
        if (previous.tagName.toLowerCase() === "input") {
          previous.focus();
          break;
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 500px) {
  .modal{
    padding: 0;
  }
}
.modal__send {
  color: #999999;
  span {
    color: #8344d6;
  }
}
</style>
