<template>
  <div class="modal" @click.self="openIsAuthActive" >
    <div class="modal__container" @click.self="openIsAuthActive">
      <app-modal-phone v-if="isShowPhone" @numberSubmitted="isShowPhone=false; isShowSms = true"/>
      <app-modal-code v-if="isShowSms" @codeSubmitted="checkStatus"/>
      <app-modal-magnum v-if="isMagnum" @closeMagnum="closeAll()"/>
      <app-modal-party v-if="isParty" @closeParty="closeAll()"/>
    </div>
  </div>
</template>

<script>
import AppModalCode from '../../components/modal/AppModalCode.vue'
import AppModalPhone from "../../components/modal/AppModalPhone.vue"
// import { ValidationProvider, ValidationObserver } from "vee-validate";
import {  mapActions } from "vuex";
import { actionTypes } from "@/store";  
import AppModalMagnum from './AppModalMagnum.vue';
import AppModalParty from './AppModalParty.vue';
export default {
  name: "AppModalRegistration",
  components: {
    // ValidationProvider,
    AppModalPhone,
    // ValidationObserver,
    AppModalCode,
    AppModalMagnum,
    AppModalParty
  },
  data() {
    return {
      user: {
        cartNumber: "",
        gender: "",
        phone: "",
        first_name: "",
        last_name: "",
        patronymic: "",
        birthday: "",
        region: "",
        email: "",
      },
      birthday_error: true,
      timer: 60,
      sms: {
        code_1: "",
        code_2: "",
        code_3: "",
        code_4: "",
      },
      currentRefs: 0,
      isShowSms: false,
      isShowPhone: true,
      isMagnum: false,
      isParty: false,
      authToken: null,
      errorsFromBackend: {
        cardNumber: null,
      },
    };
  },
  watch: {
    "user.cartNumber": {
      deep: true,
      handler() {
        this.errorsFromBackend.cardNumber = null;
      },
    },
  },
  computed: {
    smsInputRefs() {
      return [
        this.$refs.firstNumber,
        this.$refs.secondNumber,
        this.$refs.thirdNumber,
        this.$refs.fourthNumber,
      ];
    },
    getPhone() {
      return this.user.phone.replace(/[^0-9]/g, "");
    },
  },
  mounted() {
    if (this.timer === 0) {
      this.isShowAgainBtn = true;
    }
    
  },
  methods: {

    ...mapActions({
      openIsAuthActive: actionTypes.changeIsAuthActive,
    }),
    closeAll(){
      this.openIsAuthActive()
    },
    checkStatus(s){   
      if (s) {
        this.isParty = true
      } else {
        this.isMagnum = true
      }
    },
    focusPhone() {
      this.isShowPhoneSpan = true;
    },
    close() {
      this.$emit("close");
      document.querySelector("body").classList.remove("no-scroll");
    },
  },
};
</script>
<style lang="scss" scoped>
  @media (max-width: 500px) {
    .modal{
      padding: 0;
    }
  }
</style>