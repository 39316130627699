import { render, staticRenderFns } from "./AppModalMagnum.vue?vue&type=template&id=5457a975&scoped=true&"
import script from "./AppModalMagnum.vue?vue&type=script&lang=js&"
export * from "./AppModalMagnum.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5457a975",
  null
  
)

export default component.exports