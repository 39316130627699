<template>
  <div v-if="!close" class="modal">
    <div class="modal__container">
      <form class="modal__new" @submit.prevent="submitNumber"> 
        <div class="modal__new__close">
          <button @click="openIsAuthActive">
            <img src="@/assets/img/icons/close.svg" alt="" />
          </button>
        </div>
        <div class="modal__new__title">Укажите номер телефона</div>
        <div class="modal__new__descr">
          На указанный номер телефона придет СМС с кодом
        </div>
        <div class="modal__new__input">
          <span>+7</span>
          <input
            type="text"
            v-mask="' (###) ### ## ##'"
            v-model="phone"
          />
        </div>
        <input class="modal__new__button" type="submit" value="Продолжить">
        <div class="modal__new__condition">
          <input id="age" type="checkbox" />
          <label for="age"
            >Продолжая, вы подтверждаете, что Вам исполнился 21 год.</label
          >
        </div>
        <div class="modal__new__condition">
          <input id="rules" type="checkbox" />
          <label for="rules"
            >Продолжая, вы соглашаетесь с
            <span>Правилами программы лояльности,</span> даете согласия на
            обработку и хранение персональных данных, а так же на получение
            информации об акциях, скидках и персональных предложениях.</label
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {  mapActions } from "vuex";
import { actionTypes } from "@/store";  
export default {
  name: "AppModalPhone",
  methods:{
    ...mapActions({
      openIsAuthActive: actionTypes.changeIsAuthActive,
    }),
    submitNumber(){
      this.$emit('numberSubmitted')
    },
  },
  data() {
    return {
      close: false,
      phone: '',
    };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 500px) {
  .modal{
    padding: 0;
    &__container{
      width: 100%;
      height: 100%;
    }
    &__phone{
      padding: 20px;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
  } 
}
</style>
